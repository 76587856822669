import { Constants } from "@/constants/constants";
import {
  checkoutSessionsCollectionRef,
  subscriptionsCollectionRef,
} from "@/firebase/firebase-auth";
import { Logger } from "@/helpers/Logger";
import { SubscriptionObject } from "@/models/SubscriptionObject";
import * as Sentry from "@sentry/vue";
import { loadStripe } from "@stripe/stripe-js";
import { addDays } from "date-fns";
import {
  doc,
  DocumentData,
  onSnapshot,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { defineStore, storeToRefs } from "pinia";
import { v4 as generateUUID } from "uuid";
import { useFeatureFlagsStore } from "./useFeatureFlagsStore";
import { useUserStore } from "./useUserStore";
import { SubscribePageStep } from "@/models/SubscribePageStep";
import { DateFormatter } from "@/helpers/DateFormatter";
import { JourneyStage } from "@/models/JourneyStage";

interface SubscriptionState {
  subscribePageStep: SubscribePageStep;
  isSubscribing: boolean;
  subscription: SubscriptionObject;
  subscriptionStatus: string;
  firebaseUser: any;
  stripe: any;
  isFirestoreSubscribed: boolean;
}

let firebaseSubscriptionsUnsubscribe: { (): void; (): void } | null = null;
export const useSubscriptionsStore = defineStore("subscriptionsStore", {
  state: (): SubscriptionState => ({
    isSubscribing: false,
    subscription: new SubscriptionObject(),
    subscriptionStatus: "",
    firebaseUser: null,
    stripe: null,
    subscribePageStep: SubscribePageStep.Starting,
    isFirestoreSubscribed: false,
  }),

  getters: {
    isRunningInProduction: (): boolean => {
      return window.location.hostname == "app.taskangel.com";
    },
    isTesting(): boolean {
      return !this.isRunningInProduction;
    },
    haveSubscriptionsLoaded(): boolean {
      return this.isSubscribing;
    },

    freeTrialExpiryDate: (): Date | null => {
      const userStore = useUserStore();
      if (!userStore.user?.metadata) return null;
      const created = userStore.user.metadata.creationTime; // creation date as an utc string
      const createdDate = new Date(created);
      return addDays(createdDate, 7);
    },

    journeyStage: (
      state
    ):
      | JourneyStage.Registered
      | JourneyStage.InFreeTrial
      | JourneyStage.FreeTrialExpired
      | JourneyStage.Premium
      | JourneyStage.Founder
      | JourneyStage.Unregistered => {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.metadata) {
          return JourneyStage.Unregistered;
        }

        const created = userStore.user.metadata.creationTime;
        if (created) {
          const createdDate = new Date(created);
          const lastFounder = new Date(2021, 4, 1);
          if (createdDate < lastFounder) {
            return JourneyStage.Founder;
          }
        }

        if (state.subscription) {
          return JourneyStage.Premium;
        }

        if (state.subscription?.status === "active") {
          return JourneyStage.Premium;
        }

        if (created) {
          const userStore = useUserStore();
          const { user } = userStore;
          if (!user || !user.metadata) return null;

          const createdDate = new Date(created);

          const freeTrialExpiry = addDays(createdDate, 7);
          if (freeTrialExpiry > new Date()) {
            return JourneyStage.InFreeTrial;
          }
          return JourneyStage.FreeTrialExpired;
        }

        return JourneyStage.Unregistered;
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    journeyStageAsString(): string {
      try {
        if (!this.journeyStage) return "null";
        return JourneyStage[this.journeyStage];
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    canSubscribe() {
      const userStore = useUserStore();
      if (!userStore.user?.metadata) {
        return true;
      }
      const userCreated = userStore.user.metadata?.creationTime; // creation date as an utc string

      if (!userCreated) {
        return true;
      }

      if (userStore.isFounder) return false;

      if (!this.subscription) return true;
      const subscription: SubscriptionObject = this.subscription;
      if (!subscription) return true;
      return subscription.status !== "active";
    },

    canManage() {
      const userStore = useUserStore();
      const meta = userStore.user?.metadata;
      if (!meta) {
        return false;
      }
      const userCreated = meta.creationTime;
      if (!userCreated) return false;

      if (userStore.isFounder) return false;
      if (!this.subscription) return false;
      const subscription: SubscriptionObject = this.subscription;
      return subscription?.status === "active";
    },

    statusMessage(): string {
      const userStore = useUserStore();

      if (!userStore.user && !this.firebaseUser) {
        return "Please sign to see your subscription status";
      }
      const featureFlagsStore = useFeatureFlagsStore();
      const { freePassFlag } = storeToRefs(featureFlagsStore);
      if (freePassFlag.value) {
        return "You have a free pass. Enjoy!";
      }

      if (userStore.isFounder)
        return "You are a founder member, with a free subscription. Thank you.";

      if (!this.haveSubscriptionsLoaded)
        return "Waiting to load subscription details";

      if (this.subscription?.status === "active")
        return `You have a Monthly subscription. Thank you.`;
      if (this.subscription?.status === "canceled")
        return `You have cancelled your subscription. Please come back soon.`;

      if (userStore.isInFreeTrial) {
        return `Your free trial will continue until ${DateFormatter.formatDate(
          userStore.freeTrialExpiryDate
        )}.`;
      }

      return `To continue using TaskAngel, please click on the subscribe button.`;
    },
  },

  actions: {
    clearSubscriptions() {
      this.subscription = null;
      this.isSubscribing = false;
    },

    subscribeToFirestoreSubscriptions() {
      return new Promise<SubscriptionObject>((resolve, reject) => {
        if (!subscriptionsCollectionRef)
          throw new Error(
            `subscribeToFirestoreSubscriptions has no collection ref`
          );

        try {
          Logger.log("subscribeToSubscriptions starts");

          if (firebaseSubscriptionsUnsubscribe) resolve(this.subscription);
          const userStore = useUserStore();
          if (!userStore.user)
            reject(
              new Error(
                `Error when getting subscriptions. User must be logged in`
              )
            );

          Logger.log(
            `subscribeToFirestoreSubscriptions sees subscriptionsCollectionRef is ${subscriptionsCollectionRef}`
          );

          firebaseSubscriptionsUnsubscribe = onSnapshot(
            subscriptionsCollectionRef,
            (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
              try {
                const docs = snapshot.docs;
                if (!Array.isArray(docs)) return;
                if (docs.length === 0) return;

                snapshot.docs.forEach((doc) => {
                  const data = doc.data();
                  const id = doc.id;
                  const subscription = SubscriptionObject.copyFromAnyObject(
                    data,
                    id
                  );
                  Logger.log(
                    `SubscribeToSubscriptions has got a subscription: ${JSON.stringify(
                      subscription
                    )}`
                  );
                  this.subscription = subscription;
                  Logger.log(
                    `SubscribeToSubscriptions has set latest subscription in state to ${JSON.stringify(
                      this.subscription
                    )}`
                  );
                });

                this.isSubscribing = true;
                resolve(this.subscription);
              } catch (error) {
                Logger.logError("Error while processing a subscription", error);
                reject(error);
              }
            }
          );

          this.isFirestoreSubscribed = true;
        } catch (error) {
          Logger.logError("Error while subscribing to subscriptions", error);
          reject(error);
        }
      });
    },

    async loadSubscriptions(): Promise<SubscriptionObject> {
      try {
        Logger.log("LoadSubscriptions from firestore is starting");
        if (this.haveSubscriptionsLoaded) return;
        const userStore = useUserStore();
        if (!userStore.user) return;
        if (this.isSubscribing) {
          Logger.log(
            "loadSubscriptions sees this.isSubscribing is true so doesn't subscribe again"
          );
          return this.subscription;
        }
        await this.subscribeToFirestoreSubscriptions();
        Logger.log(
          `😀 LoadSubscriptions has subscribed. haveSubscriptionsLoaded is  ${
            this.haveSubscriptionsLoaded
          }. subscription is ${JSON.stringify(this.subscription)}`
        );
      } catch (error) {
        Logger.logError("Error in LoadSubscriptions", error);
      }
    },

    unsubscribeSubscriptions() {
      if (firebaseSubscriptionsUnsubscribe) {
        firebaseSubscriptionsUnsubscribe();
        firebaseSubscriptionsUnsubscribe = null;
      }
      this.isSubscribing = false;
    },

    async writeCheckoutSessionToFirestore(session: any) {
      try {
        const checkoutCollectionRef = checkoutSessionsCollectionRef;
        Logger.log(
          `writeCheckoutSessionToFirestore sees session.id is ${
            session.id
          } and collectionRef is ${JSON.stringify(checkoutCollectionRef)}`
        );
        const docRef = doc(checkoutCollectionRef, session.id);
        Logger.log(
          `writeCheckoutSessionToFirestore sees docRef for session id ${
            session.id
          } is ${JSON.stringify(docRef)}`
        );

        await setDoc(docRef, session);
        Logger.log(
          `writeCheckoutSessionToFirestore has written session ${JSON.stringify(
            session
          )}`
        );

        return docRef;
      } catch (error) {
        Logger.logError("Error writing checkout session for Firestore", error);
        throw error;
      }
    },

    async callSubscriptionCheckout() {
      const checkoutSession = {
        price: "price_1KDBcUJhaCQ1c8lR6ksI90lx",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        id: generateUUID(),
      };

      const docRef = await this.writeCheckoutSessionToFirestore(
        checkoutSession
      );

      onSnapshot(docRef, (snap: any) => {
        const { error, url } = snap.data();
        if (error) {
          Logger.logError(
            "Error while processing snapshot for checkout",
            error
          );
          throw error;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    },

    async startFreeTrial() {
      const userStore = useUserStore();
      if (!userStore.user?.uid) {
        return;
      }

      if (!userStore.user) return;

      const session = {
        id: generateUUID(),
        mode: "subscription",
        price: Constants.MONTHLY_PRICE_ID,
        success_url: "https://app.taskangel.com",
        cancel_url: "https://app.taskangel.com",
        subscription_data: {
          trial_settings: {
            end_behavior: {
              missing_payment_method: "cancel",
            },
          },
          trial_period_days: Constants.TRIAL_PERIOD_DAYS,
        },
        payment_method_collection: "if_required",
      };

      const docRef = await this.writeCheckoutSessionToFirestore(session);

      onSnapshot(docRef, async (snap: { data: () => any }) => {
        const snapData = snap.data();
        const error = snapData?.error;
        const sessionId = snapData?.sessionId;

        if (error) {
          Logger.logError("Error while processing checkout snapshot", error);
          throw error;
        }
        if (sessionId) {
          const stripeApiKey = this.isTesting
            ? process.env.STRIPE_SANDBOX_PUBLISHABLE_API_KEY
            : process.env.STRIPE_PUBLISHABLE_API_KEY;

          const stripe = await loadStripe(stripeApiKey);
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    },

    async checkoutUsingPaymentLink() {
      const userStore = useUserStore();
      // const router = useRouter();
      const { uid } = storeToRefs(userStore);
      Logger.log(`checkoutUsingPaymentLink sees user id is ${uid.value}`);
      if (!uid.value) throw new Error(`You must be logged in to do this`);
      const paymentLink: string = this.isTesting
        ? `https://buy.stripe.com/test_9AQ9Br1rQg8n2nm6oo?customer_id=${uid.value}`
        : `https://buy.stripe.com/4gw6qoaPLg2bfgAbII?customer_id=${uid.value}`;
      Logger.log(
        `checkoutUsingPaymentLink is navigating to this link: ${paymentLink}`
      );
      // if (!this.router)
      //   throw new Error(`In checkoutUsingPaymentLink, unable to access router`);
      window.open(paymentLink, "_blank");
      // await this.router.push({ path: paymentLink });
    },

    async gotoCheckout() {
      Logger.log(`useSubscriptionStore gotoCheckout called`);
      //payment link is https://buy.stripe.com/4gw6qoaPLg2bfgAbII

      const featureFlagsStore = useFeatureFlagsStore();
      const { stripeDirectFlag } = storeToRefs(featureFlagsStore);
      if (stripeDirectFlag.value) {
        Logger.log(
          `gotoCheckout sees stripeDirect flag is set and calls checkoutUsingPaymentLink`
        );
        this.checkoutUsingPaymentLink();
        return;
      }

      if (this.isTesting) return;
      const userStore = useUserStore();
      if (!userStore.user) {
        throw new Error("To subscribe you must be logged in");
      }

      const session = {
        id: generateUUID(),
        mode: "subscription",
        price: Constants.MONTHLY_PRICE_ID,
        success_url: window.location.origin, // 'https://app.taskangel.com',
        cancel_url: window.location.origin, // 'https://app.taskangel.com',
      };

      const docRef = await this.writeCheckoutSessionToFirestore(session);
      onSnapshot(docRef, async (snap: { data: () => any }) => {
        const snapData = snap.data();
        const error = snapData?.error;
        if (error) {
          Logger.logError("Error while processing checkout snapshot", error);
          throw error;
        }
        const sessionId = snapData?.sessionId;

        if (sessionId) {
          const stripeApiKey = this.isTesting
            ? process.env.STRIPE_SANDBOX_PUBLISHABLE_API_KEY
            : process.env.STRIPE_PUBLISHABLE_API_KEY;
          const stripe = await loadStripe(stripeApiKey);
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    },
  },
});
