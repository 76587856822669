import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
  User,
} from "firebase/auth";

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
//   authDomain: "taskangel-f1161.firebaseapp.com",
//   databaseURL: "https://taskangel-f1161.firebaseio.com",
//   projectId: "taskangel-f1161",
//   storageBucket: "taskangel-f1161.appspot.com",
//   messagingSenderId: "541410762395",
//   appId: process.env.VUE_APP_FIREBASE_APP_ID,
//   measurementId: "G-S1VDL12M4V",
// };
//

const firebaseConfig = {
  apiKey: "AIzaSyAU-W1NpRM2fZ-yLGwKdnY_Smnkq7ym9DA",
  authDomain: "taskangel-f1161.firebaseapp.com",
  databaseURL: "https://taskangel-f1161.firebaseio.com",
  projectId: "taskangel-f1161",
  storageBucket: "taskangel-f1161.appspot.com", // This is the correct value
  messagingSenderId: "541410762395",
  appId: "1:541410762395:web:1129c4382cd9589167303e",
  measurementId: "G-S1VDL12M4V",
};

import { Logger } from "@/helpers/Logger";
import { useUserStore } from "@/stores/useUserStore";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getFirestore,
} from "firebase/firestore";
import { reactive, toRefs } from "vue";
import { FirebaseStorage, getStorage } from "firebase/storage";

export let firebaseApp: FirebaseApp;
export let db: Firestore;
export let storage: FirebaseStorage;

try {
  firebaseApp = initializeApp(firebaseConfig);
  db = getFirestore(firebaseApp);
  Logger.log(`firebase-auth is calling getStorage()`);
  storage = getStorage(firebaseApp);
} catch (error) {
  Logger.logError(
    `Error getting firebaseApp, db, storage for config ${JSON.stringify(
      firebaseConfig
    )}`,
    error
  );
  throw error;
}

let userRef: DocumentReference<DocumentData, DocumentData>;

export const auth = initializeAuth(firebaseApp, {
  persistence: [
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
  ],
  popupRedirectResolver: browserPopupRedirectResolver,
});

export let tasksCollectionRef = null;
export let notesCollectionRef = null;
export let foldersCollectionRef = null;
export let contextsCollectionRef = null;
export let goalsCollectionRef = null;
export let subscriptionsCollectionRef = null;
export let checkoutSessionsCollectionRef = null;

const state = reactive<{
  user?: User;
  initialized: boolean;
  error: any;
}>({
  user: null,
  initialized: false,
  error: null,
});

export default function () {
  const authCheck = () => {
    return new Promise((resolve) => {
      Logger.log(`firebase-auth authCheck is running`);
      if (!state.initialized) {
        auth.onAuthStateChanged(async (_user: User) => {
          const userStore = useUserStore();
          const { setUser } = userStore;

          try {
            if (!_user) {
              userRef = null;
              tasksCollectionRef = null;
              notesCollectionRef = null;
              foldersCollectionRef = null;
              contextsCollectionRef = null;
              goalsCollectionRef = null;
              subscriptionsCollectionRef = null;
              checkoutSessionsCollectionRef = null;
              resolve(_user);
            }
            if (_user) {
              try {
                userRef = doc(db, "users", _user.uid);

                if (userRef instanceof DocumentReference) {
                  Logger.log(
                    `firebase-auth authCheck is getting storage for user ${_user.uid}`
                  );

                  if (storage)
                    Logger.log(
                      `firebase-auth has got storage  for user ${_user.uid} 😀`
                    );
                  tasksCollectionRef = collection(userRef, "tasks");
                  notesCollectionRef = collection(userRef, "notes");
                  foldersCollectionRef = collection(userRef, "folders");
                  contextsCollectionRef = collection(userRef, "contexts");
                  goalsCollectionRef = collection(userRef, "goals");
                  subscriptionsCollectionRef = collection(
                    userRef,
                    "subscriptions"
                  );
                  checkoutSessionsCollectionRef = collection(
                    userRef,
                    "checkout_sessions"
                  );

                  state.user = _user;
                  setUser(_user);
                }
              } catch (error) {
                Logger.logError(
                  `Error creating userRef, collections refs and getting storage. Firebase config was ${JSON.stringify(
                    firebaseConfig
                  )}`,
                  error
                );
                throw error;
              }
            }
            state.initialized = true;
            resolve(_user);
          } catch (error) {
            Logger.logError("error in authCheck", error);
            throw error;
          }
        });
      }
    });
  };

  return {
    ...toRefs(state),
    db,
    authCheck,
  };
}
