import { Constants } from "@/constants/constants";
import { contextsCollectionRef, db } from "@/firebase/firebase-auth";
import { Logger } from "@/helpers/Logger";
import { Sorter } from "@/sorters/Sorter";
import { ContextObject } from "@/models/ContextModel";
import { MyNotification } from "@/models/Notification";
import { useNotificationsStore } from "@/stores/useNotifications";
import * as Sentry from "@sentry/vue";
import {
  collection,
  doc,
  DocumentChange,
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as generateUUID } from "uuid";
import { useUserStore } from "./useUserStore";

let firebaseContextsUnsubscribe: { (): void; (): void } | null = null;

export const useContextsStore = defineStore("contextsStore", {
  state: () => ({
    contexts: Array<ContextObject>(),
    haveContextsLoaded: false,
    mostRecentId: "",
    isFirestoreSubscribed: false,
  }),

  /** Getters should be fat arrow functions unless they need to call other getters. That requires the use of this, which is only available in non-arrow functions.
   *
   */
  getters: {
    getContexts: (state) => {
      return state.contexts ?? [];
    },

    allContexts: (state) => {
      return state.contexts ?? [];
    },

    contextFromId:
      (state) =>
      (id: string): ContextObject | undefined => {
        return state.contexts.find((item) => item.id === id);
      },

    contextFromTitle:
      (state) =>
      (title: string): ContextObject | undefined => {
        const candidates =
          state.contexts && state.contexts.filter((f) => f.title === title);
        return (candidates && candidates[0]) || undefined;
      },

    allContextsCount: (state) => {
      return state.contexts?.length || 0;
    },
  },

  actions: {
    subscribeToFirestoreContexts() {
      return new Promise<ContextObject[]>((resolve, reject) => {
        if (!contextsCollectionRef)
          throw new Error(`subscribeToFirestoreContexts has no collection ref`);
        try {
          firebaseContextsUnsubscribe = onSnapshot(
            contextsCollectionRef,
            async (snap: QuerySnapshot) => {
              try {
                if (this.haveContextsLoaded) {
                  Logger.log(
                    `loadContexts snapshot sees contexts have loaded so looks at changes`
                  );
                  const changes = snap.docChanges();
                  if (!Array.isArray(changes)) return;
                  if (changes.length === 0) return;
                  changes.forEach((change) => {
                    this.processFirestoreChange(change);
                  });
                  this.contexts = Sorter.copyAndSortContexts(this.contexts);
                  resolve(this.contexts);
                  return;
                }

                const docs = snap.docs;
                if (!Array.isArray(docs)) return;
                if (docs.length === 0) return;

                const contextsFromFirestore = this.convertFirestoreDocs(docs);
                this.contexts = Sorter.copyAndSortContexts(
                  contextsFromFirestore
                );
                this.haveContextsLoaded = true;
                resolve(this.contexts);
                return;
              } catch (innerError) {
                Logger.logError(
                  `Error in firebase contexts onSnapshot, rejecting promise`,
                  innerError
                );
                reject(innerError);
              }
            }
          );

          this.isFirestoreSubscribed = true;
        } catch (error) {
          Logger.logError(
            `Error in subscribeToFirebaseContexts, rejecting promise`,
            error
          );
          reject(error);
        }
      });
    },
    processFirestoreChange(change: DocumentChange<DocumentData, DocumentData>) {
      const data = change.doc.data();
      const id = change.doc.id;
      const context = new ContextObject({ ...data, id: id });
      if (change.type === "added") this.processFirestoreAdded(context);
      else if (change.type === "modified")
        this.processFirestoreModified(context);
      else if (change.type === "removed") this.processFirestoreRemoved(context);
    },

    processFirestoreAdded(context: ContextObject) {
      const exists = !!this.contextFromId(context.id);
      if (!exists) this.contexts.push(context);
    },

    processFirestoreModified(context: ContextObject) {
      context.modified = new Date();
      const contextIndex = this.contexts.findIndex(
        (t: ContextObject) => t.id == context.id
      );
      if (contextIndex < 1) return;

      this.contexts.splice(contextIndex, 1);
      this.contexts.push(context);
    },

    processFirestoreRemoved(context: ContextObject) {
      const contextIndex = this.contexts.findIndex(
        (t: ContextObject) => t.id == context.id
      );
      if (contextIndex < 1) {
        return;
      }
      this.contexts.splice(contextIndex, 1);
    },

    convertFirestoreDocs(
      docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]
    ) {
      const result = Array<ContextObject>();
      docs.forEach((doc: any) => {
        const context = this.convertFirestoreDoc(doc);
        result.push(context);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const data = doc.data();
      return new ContextObject({ ...data, id: doc.id });
    },

    // subscribeToFirestoreContexts() {
    //   try {

    //     const userStore = useUserStore();
    //     const userDocRef = userStore.userDocRef;
    //     const collectionRef = collection(userDocRef, "contexts")

    //     firebaseContextsUnsubscribe = onSnapshot(collectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
    //       try {

    //         const isListeningForChanges = this.haveContextsLoaded;

    //         if (isListeningForChanges) {

    //           snapshot.docChanges().forEach((change) => {
    //             const data = change.doc.data();
    //             const id = change.doc.id;
    //             const context = ContextObject.copyFromAnyObject(
    //               data,
    //               id
    //             );

    //             if (change.type === "added") {
    //               if (!context.title || !context.id) return;
    //               if (this.contextWithIdExistsId) return;
    //               this.addContextToState(context);
    //             }
    //             if (change.type === "modified") {
    //               if (!context.title || !context.id) return;
    //               context.modified = DateTime.utc();
    //               this.removeContextWithIdFromState(context.id)
    //               this.addContextToState(context);
    //             }
    //             if (change.type === "removed") {
    //               this.removeContextWithIdFromState(context.id)
    //             }
    //           });

    //           Sorter.sortCotextsInPlace(this.contexts);

    //           return true;
    //         }

    //         const isLoadingEverything = !this.haveContextsLoaded;
    //         if (isLoadingEverything) {
    //           snapshot.docs.forEach((doc) => {
    //             const data = doc.data();
    //             const id = doc.id;
    //             const context = ContextObject.copyFromAnyObject(data, id);
    //             if (!!context.id && !!context.title)
    //               this.addContextToState(context);
    //           })

    //           Sorter.sortCotextsInPlace(this.contexts)
    //         }

    //         const result = snapshot.docs.map((doc) => {

    //           const data = doc.data();
    //           const id = doc.id;
    //           const context = ContextObject.copyFromAnyObject(data, id);
    //           return context;
    //         });

    //         if (!result) {
    //           this.contexts = [];
    //         } else {
    //           const withTitles = result.filter((c) => !!c.title);
    //           Sorter.sortCotextsInPlace(withTitles);
    //           this.contexts = withTitles;
    //         }

    //         this.haveContextsLoaded = true;
    //         return true;

    //       } catch (error) {
    //         console.error(`subscribeToFirestoreContexts error ${error.message ?? error}`)
    //         Sentry.captureException(error, {
    //           tags: {
    //             section: "subscribeToFirestoreContexts",
    //           },
    //         });
    //       }

    //     });

    //     this.isFirestoreSubscribed = true;
    //   } catch (error) {
    //     Sentry.captureException(error),
    //     {
    //       tags: {
    //         section: "subscribeToFirestoreContexts",
    //       },
    //     };
    //   }

    // },
    async loadContexts(): Promise<ContextObject[]> {
      try {
        if (this.haveContextsLoaded) return this.contexts;
        const userStore = useUserStore();
        if (!userStore.user) return;
        if (firebaseContextsUnsubscribe) return;
        const result = await this.subscribeToFirestoreContexts();
        Logger.log(
          `loadContexts has loaded ${result.length} contexts, setting haveContextsLoaded to true`
        );
        this.haveContextsLoaded = true;
        return result;
      } catch (error) {
        Logger.logError("Error in loadContexts", error);
        throw error;
      }
    },

    unsubscribeContexts() {
      if (firebaseContextsUnsubscribe) {
        firebaseContextsUnsubscribe();
        this.haveContextsLoaded = false;
      }
    },

    clearContexts() {
      this.contexts = [];
      this.haveContextsLoaded = false;
    },

    addContextToStateAndSort(context: ContextObject) {
      const exists = !!this.contextFromId(context.id);
      if (exists) return;
      this.contexts.push(context);
      Sorter.sortContextsInPlace(this.contexts);
    },

    addContextToState(context: ContextObject) {
      const exists = !!this.contextFromId(context.id);
      if (exists) return;
      this.contexts.push(context);
    },

    async addContext(context: ContextObject) {
      if (!context.id) context.id = generateUUID();
      this.addContextToState(context);
      this.writeContextToFirestore(context);
    },

    async writeContextToFirestore(context: ContextObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "contexts");
      const docRef = doc(collectionRef, context.id);
      const obj = context.copyToFirestoreObject();
      await setDoc(docRef, obj);
    },

    async editContext(context: ContextObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;
        const userDocRef = doc(db, "users", userStore.user.uid);

        if (!userDocRef) {
          return;
        }

        if (context.id == null) {
          throw new Error(`Unable to edit context as it has no id`);
        }

        context.modified = new Date();
        const contextIndex: number = this.contexts.findIndex(
          (t: ContextObject) => t.id == context.id
        );

        if (contextIndex < 0) {
          return;
        }

        this.removeContextFromStateAtIndex(contextIndex);
        this.contexts.push(context);

        await this.writeContextToFirestore(context);
      } catch (error: any) {
        Logger.logError("Error in editContext while writing to fs", error);
        throw error;
      }
    },

    makeDefaultContext() {
      const context = new ContextObject({});
      return context;
    },

    removeContextFromStateAtIndex(index: number) {
      this.contexts.splice(index, 1);
    },

    removeContextWithIdFromState(id: string) {
      const foundIndex = this.contexts.findIndex(
        (context) => context.id === id
      );
      if (foundIndex >= 0) this.removeContextFromStateAtIndex(foundIndex);
    },

    async removeContext(id: string) {
      await this.removeContexts([id]);
    },

    async removeContexts(ids: string[]) {
      if (!ids) return;
      const userStore = useUserStore();
      for (const id of ids) {
        const contextIndex: number = this.contexts.findIndex(
          (t: ContextObject) => t.id == id
        );
        this.removeContextFromStateAtIndex(contextIndex);
      }
      try {
        let batch = writeBatch(db);
        let itemsInBatch = 0;

        for (const id of ids) {
          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "contexts");
          const docRef = doc(collectionRef, id);

          batch.delete(docRef);
        }

        await batch.commit();
      } catch (error: any) {
        Sentry.captureException(error);
        Sentry.captureMessage(`Failure in UseContextsStore.removeContexts`);
        const notification = MyNotification.fromError(
          error,
          "when removing contexts"
        );
        const notifications = useNotificationsStore();
        notifications.addNotification(notification);
      }
    },

    unsubscribeFirestore() {
      if (firebaseContextsUnsubscribe) {
        firebaseContextsUnsubscribe();
        this.isFirestoreSubscribed = false;
      }
    },
  },
});
