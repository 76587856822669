import { createRouter, createWebHistory } from "@ionic/vue-router";
// import { useIonRouter } from "@ionic/vue";
import { RouteRecordRaw, isNavigationFailure } from "vue-router";
// import { store } from "../store/Store";
import ErrorDisplay from "@/views/components/ErrorDisplay.vue";
// import { currentUser } from "@/firebase/firebase-auth";
import { Logger } from "@/helpers/Logger";
import { useUserStore } from "@/stores/useUserStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/pages/HomePage/HomePage.vue"),
  },
  // redirect from old bookmark...
  {
    path: "/tabs/tab1",
    redirect: "/",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/LoginPage.vue"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("@/views/pages/Subscribe.vue"),
  },
  {
    path: "/ifttt/authorize",
    name: "ifttt-authorize",
    component: () => import("@/views/pages/iftttAuthorize.vue"),
  },
  {
    path: "/zapier/authorize",
    name: "zapier-authorize",
    component: () => import("@/views/pages/zapier-authorization.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/pages/RegisterPage.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/pages/RegisterPage.vue"),
  },
  {
    path: "/signup1",
    name: "signup1",
    component: () => import("@/views/pages/Signup/SignupStep1.vue"),
  },
  {
    path: "/signup2",
    name: "signup2",
    component: () => import("@/views/pages/Signup/SignupStep2.vue"),
  },
  {
    path: "/signup3",
    name: "signup3",
    component: () => import("@/views/pages/Signup/SignupStep3.vue"),
  },
  {
    path: "/signupthanks",
    name: "signupthanks",
    component: () => import("@/views/pages/Signup/SignupThanks.vue"),
  },
  {
    path: "/expired",
    name: "expired",
    component: () => import("@/views/pages/TrialExpiredPage.vue"),
  },
  {
    path: "/unregister",
    name: "unregister",
    component: () => import("@/views/pages/UnregisterPage.vue"),
  },
  {
    path: "/regthankyou",
    name: "regthankyou",
    component: () => import("@/views/pages/StripeThankyouPage.vue"),
  },
  {
    path: "/stripethankyou",
    name: "stripethankyou",
    component: () => import("@/views/pages/RegThankyouPage.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/pages/SettingsPage/SettingsPage.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/pages/UserProfilePage.vue"),
  },
  {
    path: "/task/:id",
    name: "task",
    component: () => import("@/views/pages/TaskPage.vue"),
  },
  {
    path: "/note/:id",
    name: "note",
    component: () => import("@/views/pages/NotePage.vue"),
  },
  {
    path: "/goal/:id",
    name: "goal",
    component: () => import("@/views/pages/GoalPage.vue"),
  },
  {
    path: "/goals",
    name: "Goals",
    component: () => import("@/views/pages/GoalList.vue"),
  },
  {
    path: "/folder/:id",
    name: "folder",
    component: () => import("@/views/pages/FolderPage.vue"),
  },
  {
    path: "/folders",
    name: "Folders",
    component: () => import("@/views/pages/FolderList.vue"),
  },

  {
    path: "/context/:id",
    name: "context",
    component: () => import("@/views/pages/ContextPage.vue"),
  },
  {
    path: "/contexts",
    name: "Contexts",
    component: () => import("@/views/pages/ContextList.vue"),
  },
  {
    path: "",
    redirect: "tasks",
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/pages/MasterPage.vue"),
  },
  {
    path: "/tasks/new",
    name: "tasks-new",
    component: () => import("@/views/pages/MasterPage.vue"),
  },
  {
    path: "/tasks/filter/:filter",
    name: "tasks-filter",
    component: () => import("@/views/pages/MasterPage.vue"),
  },
  // {
  //   path: "completed-tasks",
  //   name: "completed-tasks",
  //   component: () => import("@/views/pages/MasterPage.vue"),
  // },
  // {
  //   path: "tasks/all",
  //   name: "all-tasks",
  //   component: () => import("@/views/pages/MasterPage.vue"),
  // },
  // {
  //   path: "tasks/today",
  //   name: "tasks-today",
  //   component: () => import("@/views/pages/MasterPage.vue"),
  // },
  // {
  //   path: "tasks/critical",
  //   name: "tasks-critical",
  //   component: () => import("@/views/pages/MasterPage.vue"),
  // },
  // {
  //   path: "tasks/new",
  //   name: "tasks-new",
  //   component: () => import("@/views/pages/MasterPage.vue"),
  // },
  {
    path: "/notes",
    name: "notes",
    component: () => import("@/views/pages/NotesMasterPage.vue"),
  },
  // {
  //   path: "notes/all",
  //   name: "all-notes",
  //   component: () => import("@/views/pages/NotesMasterPage.vue"),
  // },
  // {
  //   path: "notes/recent",
  //   name: "recent-notes",
  //   component: () => import("@/views/pages/NotesMasterPage.vue"),
  // },
  // {
  //   path: "tab3",
  //   component: () => import("@/views/pages/SettingsPage/SettingsPage.vue"),
  // },
  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    component: ErrorDisplay,
  },

  // {
  //   path: "/tabs/",
  //   component: Tabs,
  //   children: [

  //   ],
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const ionRouter = useIonRouter();

router.beforeEach(async (to, from, next) => {
  try {
    // console.log(`router sees new path ${to.path}`, to)
    // send a message to app, in case it wants to reload itself between pages for a sw update
    // console.log(`router sends navigating message`, to)
    document.dispatchEvent(new CustomEvent("navigating", { detail: to }));

    // console.log(`after sending navigating event, result is ${eventResult}`)

    // guard to see if user is logged in
    // const userStore = useUserStore();
    // const { isLoggedIn, user} = storeToRefs(userStore);
    // console.log(`router beforeEach sees user, isLoggedIn is ${isLoggedIn.value}`, user.value);

    // console.log(`Router sees Firebase has currentUser ${JSON.stringify(currentUser)}`)

    // console.log(`Router sees Firebase has db ${JSON.stringify(db)}`)
    // check we are logged in, ie we have a user, or we are going to the login page

    const userStore = useUserStore();
    if (
      !!userStore.user ||
      to.path === "/subscribe" ||
      to.path === "/login" ||
      to.path === "/register" ||
      to.path === "/welcome" ||
      to.path === "/ifttt/authorize" ||
      to.name === "signup2" ||
      to.name === "signup3" ||
      to.name === "signupthanks"
    ) {
      Logger.log(`🦮 router allows navigation to  ${to.path}`);
      next();
    } else {
      Logger.log(`🦮 router redirects to login page from  ${to.path}`);
      next({
        name: "login",
        replace: true,
      });
    }
  } catch (error) {
    Logger.logError(`router.beforeEach failed with error`, error);
    // Sentry.captureMessage(`Failure in router beforeEach`);
    // Sentry.captureException(error, {
    //   tags: {
    //     section: "router",
    //   },
    // });
  }
});

router.afterEach((to, _, failure) => {
  if (isNavigationFailure(failure)) {
    // console.log('failed navigation', failure)
    return;
  }
  // const canGoBack = ionRouter.canGoBack
  // console.log(`Router has navigated to ${to.path}.`)
  document.dispatchEvent(new CustomEvent("navigated", { detail: to }));
});

export default router;
