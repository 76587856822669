import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

import { useSettingsStore } from "@/stores/useSettingsStore";
import { useTasksStore } from "@/stores/useTasksStore";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  menuController,
  toastController,
} from "@ionic/vue";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import FilterPanel from "./views/components/FilterPanel.vue";
import NoteFilterPanel from "./views/components/NoteFilterPanel.vue";
import NotificationContainer from "./views/components/NotificationContainer.vue";

import * as Sentry from "@sentry/vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useFeatureFlagsStore } from "./stores/useFeatureFlagsStore";
import { useFiltersStore } from "./stores/useFiltersStore";
import InstallPWA from "./views/components/InstallPWA.vue";
import { Logger } from "@/helpers/Logger";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

let refreshing = false;
let newRegistration: any;
let hasUpdate = false;

const currentPath = ref("");

const settings = useSettingsStore();
const tasksStore = useTasksStore();
const { unsubscribeFirestore } = tasksStore;

const { showFilterMenu } = storeToRefs(settings);
const featureFlagsStore = useFeatureFlagsStore();
const filtersStore = useFiltersStore();
const { autoUpdate, installPWAFlag } = storeToRefs(featureFlagsStore);

const refreshApp = async () => {
  if (refreshing) {
    return;
  }
  refreshing = true;
  if (!newRegistration || !newRegistration.waiting) {
    return;
  }

  try {
    await newRegistration?.waiting?.postMessage("skipWaiting");
    window.location.reload();
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        section: "refreshApp",
      },
    });
  }
};

const openToastOptions = async () => {
  const toast = await toastController.create({
    header: "New Version Available",
    message: `Do you want to update TaskAngel now?`,
    position: "top",
    cssClass: "toast-custom-class",
    buttons: [
      {
        side: "end",
        icon: "star",
        text: "Yes",
        handler: () => {
          refreshApp();
        },
      },
      {
        text: "Later",
        role: "cancel",
        handler: () => {
          refreshing = false;
        },
      },
    ],
  });
  await toast.present();

  await toast.onDidDismiss();
};

const updateAvailable = (event: any) => {
  newRegistration = event.detail;
  if (!newRegistration) {
    return;
  }
  if (autoUpdate.value) {
    hasUpdate = true;
    return;
  }
  openToastOptions();
};

const onNavigated = async () => {
  if (hasUpdate) {
    hasUpdate = false;
    refreshApp();
    return;
  }
};

const onControllerChange = () => {
  refreshing = false;
  document.removeEventListener("swUpdated", updateAvailable);
  document.removeEventListener("controllerchange", onControllerChange);
  window.location.reload();
};

document?.addEventListener("swUpdated", updateAvailable, { once: true });
document?.addEventListener("navigated", onNavigated);

navigator.serviceWorker?.addEventListener(
  "controllerchange",
  onControllerChange
);

const areTasksShowing = ref(false);
const areNotesShowing = ref(false);

const canShowFilterMenu = ref(true);

const isFilterMenuShowing = computed(() => {
  const result = canShowFilterMenu.value && showFilterMenu.value;
  return result;
});

const userHidMenu = ref(false);
const windowWidth = ref(window.innerWidth);

const isWideScreen = computed(() => {
  return windowWidth.value > 768;
});

const route = useRoute();
watch(
  route,
  (to) => {
    currentPath.value = to.path;
    areTasksShowing.value =
      currentPath.value.includes("tasks") || to.path.includes("tab1");
    areNotesShowing.value =
      currentPath.value.includes("notes") || to.path.includes("tab2");
    canShowFilterMenu.value = areTasksShowing.value || areNotesShowing.value;
  },
  { flush: "pre", immediate: true, deep: true }
);

const handleFilterSelected = async () => {
  if (userHidMenu.value) {
    userHidMenu.value = false;
  } else {
    userHidMenu.value = true;
  }

  if (!isWideScreen.value) {
    await menuController.close();
  }
};

onBeforeUnmount(() => {
  unsubscribeFirestore();
});

onMounted(async () => {
  await settings.init();
  await featureFlagsStore.init();
  await filtersStore.init();

  Logger.log(`App is mounted`);

  window?.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IonApp), { id: "app" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonHeader), null, {
          default: _withCtx(() => [
            (_unref(installPWAFlag))
              ? (_openBlock(), _createBlock(InstallPWA, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (isWideScreen.value)
          ? (_openBlock(), _createBlock(_unref(IonSplitPane), {
              key: 0,
              "content-id": "main",
              when: "md",
              disabled: !isFilterMenuShowing.value
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonMenu), {
                  side: "start",
                  "menu-id": "first",
                  "content-id": "main"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonContent), null, {
                      default: _withCtx(() => [
                        (areTasksShowing.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createVNode(FilterPanel)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createVNode(NoteFilterPanel)
                            ]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(IonRouterOutlet), { id: "main" })
              ]),
              _: 1
            }, 8, ["disabled"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(IonMenu), {
                side: "start",
                "menu-id": "first",
                "content-id": "main"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonHeader), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonToolbar), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonTitle), null, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Menu")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonContent), null, {
                    default: _withCtx(() => [
                      (areTasksShowing.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(FilterPanel, {
                              onSelected: handleFilterSelected,
                              onClosed: handleFilterSelected
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (areNotesShowing.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(NoteFilterPanel, { onSelected: handleFilterSelected })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonRouterOutlet), { id: "main" })
            ])),
        _createElementVNode("div", null, [
          _createVNode(NotificationContainer)
        ])
      ]),
      _: 1
    })
  ]))
}
}

})